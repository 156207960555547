
import { flag } from '@/config'
import { rotate } from '@/modules/app/app'
import { MarketState } from '@/modules/market/market.types'
import TheRotateChart from '@/modules/market/symbolRotate/TheRotateChart.vue'
import Page from '@/provider/Page.vue'
import Pic from '@/components/Pic.vue'
import TheMarketClosedTip from '@/modules/market/components/TheMarketClosedTip.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { useQuote } from '@/modules/market/market'
import { computed, defineComponent, watchEffect, onBeforeUnmount } from 'vue'
import datafeed from '@/common/datafeed'
import { useRoute } from 'vue-router'
import router from '@/router'

export default defineComponent({
  name: 'Symbol',
  components: {
    TheRotateChart,
    Page,
    TheMarketClosedTip,
    RealTimeQuote,
    Pic,
  },
  setup () {
    const route = useRoute()
    const symbol = useQuote(route.params.code as string, true)

    watchEffect(() => {
      datafeed.unSubscribe([], true)
      datafeed.subscribe([route.params.code as string], true)
    })
    onBeforeUnmount(() => {
      datafeed.unSubscribe([], true)
    })

    const back = () => {
      setTimeout(() => {
        router.back()
      }, 200)
    }

    const closeClick = () => {
      rotate('vertical').then(back)
    }

    return {
      isClosed: computed(() => symbol.value?.isClosed === MarketState.CLOSED),
      symbol,
      closeClick,
    }
  },
})
