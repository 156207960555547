
import { marketFeed } from '@/common/datafeed'
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import { events } from '@/config'
import { useDrawing } from '@/modules/market/components/chart/chart'
import TheCrosshair from '@/modules/market/components/chart/TheCrosshair.vue'
import TheHorizontalScan from '@/modules/market/components/chart/TheHorizontalScan.vue'
import ThePriceLabel from '@/modules/market/components/chart/ThePriceLabel.vue'
import {
  BarTypes,
  panelStudies, readResolutions, ResolutionConfig, StudyConfig,
} from '@/modules/market/components/chart/chartConfig'
import datafeed from '@/modules/market/components/chart/datafeed'
import TheResolutionBar from '@/modules/market/components/chart/TheResolutionBar.vue'
import TheStudyBar from '@/modules/market/components/chart/TheStudyBar.vue'
import { useOverlayStudies } from '@/modules/market/market'
import { readLines } from '@/modules/market/market.api'
import RatioFixedArea from 'common/RatioFixedArea.vue'
import { off, on } from 'essential/tools/event'
import HappyChart, { ChartType } from 'happychart'
import { ChartPointer } from 'happychart/interface/IChart'
import { defineComponent, onBeforeUnmount, onMounted, shallowRef, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import Chart from 'happychart/chart/Chart'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheChart',
  components: {
    TheHorizontalScan,
    TheCrosshair,
    Pic,
    ThePriceLabel,
    TheStudyBar,
    Line,
    RatioFixedArea,
    TheResolutionBar,
  },
  setup () {
    const route = useRoute()
    let hc: HappyChart | null = null
    let mainChart: Chart | null = null
    let studyChart: Chart | null = null
    const price = shallowRef('')
    const position = shallowRef({})
    const crosshair = shallowRef<ChartPointer | null>(null)
    const chartGridColor = 'rgba(244,246, 248, 0.5)'
    const resolutionValue = shallowRef(BarTypes.K_1)

    const [overlayStudies, defaultOverlayStudy, changeOverlayStudy] = useOverlayStudies(config => {
      mainChart?.removeAllStudies()
      if (!R.isEmpty(config.input)) {
        mainChart?.addStudy(config.value, config.input)
      }
    })

    const { draw } = useDrawing()

    const setup = (resolution: ResolutionConfig) => {
      hc = new HappyChart({
        crosshair: true,
        restoreButton: '#restore',
        periodicity: resolution.period,
        container: 'hc_rotate_chart',
        datafeed: datafeed,
        yAxis: {
          borderColor: chartGridColor,
          lineColor: chartGridColor,
        },
        xAxis: {
          borderColor: chartGridColor,
          lineColor: chartGridColor,
        },
      })
      resolutionValue.value = resolution.value

      const symbol = route.params.code as string

      mainChart = hc.loadChart(symbol, {
        type: ChartType.CANDLE,
        layout: {
          whitespace: {
            right: 80,
          },
          candleWidth: 8,
        },
      })

      mainChart.onCrosshairMove((data: ChartPointer | null) => {
        crosshair.value = data
      })

      mainChart.onTick((bar, x, y) => {
        price.value = bar.c.toString()
        position.value = {
          top: `${y - 7}px`,
          left: 0,
        }
      })

      if (defaultOverlayStudy) {
        mainChart.addStudy(defaultOverlayStudy.value, defaultOverlayStudy.input)
      }

      studyChart = hc.loadChart(symbol, [], {
        container: 'ch_rotate_chart',
        showChart: false,
        layout: {
          whitespace: {
            right: 80,
          },
          candleWidth: 8,
        },
        yAxis: {
          showBorder: false,
          showTicks: false,
        },
        xAxis: {
          display: false,
        },
      })

      const defaultPanelStudy = R.find(s => s?.default ?? false, panelStudies) ?? panelStudies[0]
      studyChart.addStudy(defaultPanelStudy.value, defaultPanelStudy.input)

      mainChart.sync(studyChart)
      studyChart.sync(mainChart)

      watchEffect(() => {
        const code = route.params.code
        if (code && resolutionValue.value) {
          readLines({ code: route.params.code })
            .then(resp => draw(
              mainChart,
              R.filter(R.propEq('kLineType', resolutionValue.value), resp),
            ))
        }
      })
    }

    onMounted(() => {
      const defaultPeriodicity = R.find(r => r?.default ?? false, readResolutions())

      if (!defaultPeriodicity) {
        return
      }

      setTimeout(() => {
        setup(defaultPeriodicity)
      }, 0)
    })

    const changeResolution = (r: ResolutionConfig) => {
      resolutionValue.value = r.value
      if (hc) {
        hc.setPeriodicity(r.period)
      } else {
        setup(r)
      }
    }

    const changePanelStudy = (study: StudyConfig, apply: (item: StudyConfig) => void) => {
      studyChart?.removeAllStudies()
      studyChart?.addStudy(study.value, study.input)
      apply(study)
    }

    const connect = () => {
      const code = route.params.code as string

      if (code) {
        marketFeed.subscribe([code])
        hc?.loadChartData()
      }
    }

    on(events.activated, connect)

    onBeforeUnmount(() => {
      off(events.activated, connect)
      hc?.destroy()
    })

    return {
      defaultOverlayStudy: defaultOverlayStudy.value,
      changeResolution,
      changeOverlayStudy,
      changePanelStudy,
      overlayStudies,
      panelStudies,
      price,
      crosshair,
      position,
      code: route.params.code,
    }
  },
})
