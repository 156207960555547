<template>
<Page>
  <div class="spread df-col">
    <RealTimeQuote :symbol="symbol" #="{code, price, buy, spread, margin, mp, isRise}">
      <div class="sy-top px-16 df-middle c-success t-center" :class="{'c-danger': !isRise}">
        <span class="f-bold f-lg c-text mr-8">{{code}}</span>
        <div class="flex-1 t-left df-middle f-lg">
          {{buy}} <span class="diff c-text mx-4"> {{spread}} </span> {{price}}
        </div>
        <div class="flex-1 t-left">
          <span>{{margin}}</span>
          <span class="ml-24">{{mp}}</span>
        </div>
        <Pic @click="closeClick" class="close" src="trade/close" width="24" height="24" />
      </div>
    </RealTimeQuote>
    <TheRotateChart />
    <TheMarketClosedTip v-if="isClosed" :symbol="symbol" />
  </div>
</Page>
</template>

<script lang="ts">
import { flag } from '@/config'
import { rotate } from '@/modules/app/app'
import { MarketState } from '@/modules/market/market.types'
import TheRotateChart from '@/modules/market/symbolRotate/TheRotateChart.vue'
import Page from '@/provider/Page.vue'
import Pic from '@/components/Pic.vue'
import TheMarketClosedTip from '@/modules/market/components/TheMarketClosedTip.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { useQuote } from '@/modules/market/market'
import { computed, defineComponent, watchEffect, onBeforeUnmount } from 'vue'
import datafeed from '@/common/datafeed'
import { useRoute } from 'vue-router'
import router from '@/router'

export default defineComponent({
  name: 'Symbol',
  components: {
    TheRotateChart,
    Page,
    TheMarketClosedTip,
    RealTimeQuote,
    Pic,
  },
  setup () {
    const route = useRoute()
    const symbol = useQuote(route.params.code as string, true)

    watchEffect(() => {
      datafeed.unSubscribe([], true)
      datafeed.subscribe([route.params.code as string], true)
    })
    onBeforeUnmount(() => {
      datafeed.unSubscribe([], true)
    })

    const back = () => {
      setTimeout(() => {
        router.back()
      }, 200)
    }

    const closeClick = () => {
      rotate('vertical').then(back)
    }

    return {
      isClosed: computed(() => symbol.value?.isClosed === MarketState.CLOSED),
      symbol,
      closeClick,
    }
  },
})
</script>

<style lang="scss" scoped>
.sy-top {
  height: 48px;
  min-height: 48px;
  max-height: 48px;
}

.close {
  background-color: var(--color-grey);
  border-radius: 50%;
}

.diff {
  padding: 0 1px;
  font-size: 11px;
  border: 1px solid var(--color-title);
  border-radius: 2px;
}
</style>
